<template>
  <!-- 全场活动 -->
  <div>
    <div v-if="isShow">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>全场活动</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload"
          ><i style="font-size: 18px" class="el-icon-refresh-right"></i
        ></el-button>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-wrap: wrap;
          padding: 50px 20px;
          width: 80%;
        "
      >
        <div
          v-for="activity in activitys"
          :key="activity.id"
          @click="activityShow(activity.id)"
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 20px;
            width: 200px;
            height: 270px;
            margin-left: 30px;
            margin-bottom: 30px;
            cursor: pointer;
          "
        >
          <img
            style="width: 50px; height: 50px; margin-top: 30px"
            :src="activity.pic"
            alt=""
          />
          <p style="font-size: 36px; color: #333; margin: 10px 0">
            {{ activity.title }}
          </p>
          <p style="width: 158px; font-size: 14px; font-family: 'PingFang SC'">
            {{ activity.tip }}
          </p>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__fadeInUpBig"
      leave-active-class="animate__fadeOutUpBig"
    >
      <div>
        <div v-if="!isShow">
          <active-group
            :breadcrumb="breadcrumb[label]"
            @closeClick="closeClick"
          ></active-group>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    activeGroup: () => import("./childrens/overallActiveGroup.vue"),
  },
  data() {
    return {
      isShow: true,
      breadcrumb: [
        "满减活动",
        "满折活动",
        "满赠活动",
        "满包邮活动",
        "二维码活动",
        "消费送活动",
        "新手礼包活动",
        "弹窗活动",
      ],
      label: 0,
      activitys: [
        {
          id: 1,
          pic: require("@/assets/mianAssets/label6_assets/满减.png"),
          title: "满减",
          tip: "例：满200减50、满100减30、满50减10",
        },
        {
          id: 2,
          pic: require("@/assets/mianAssets/label6_assets/折扣.png"),
          title: "折扣",
          tip: "例：满200打5折、满100打8折、满50打9折",
        },
        {
          id: 3,
          pic: require("@/assets/mianAssets/label6_assets/满赠.png"),
          title: "满赠",
          tip: "例：满足条件后赠送礼品或者优惠劵",
        },
        {
          id: 4,
          pic: require("@/assets/mianAssets/label6_assets/包邮.png"),
          title: "包邮",
          tip: "例：满200包邮、满100包邮、满50包邮",
        },
        {
          id: 5,
          pic: require("@/assets/mianAssets/label6_assets/二维码.png"),
          title: "二维码",
          tip: "例：线下实物卡上的二维码，可自定义给每张卡设优惠政策",
        },
        {
          id: 6,
          pic: require("@/assets/mianAssets/label6_assets/消费送.png"),
          title: "消费送",
          tip: "例：自定义消费满设定的金额后，送优惠券或礼品；订单完成后赠送的礼品或券自动到卡包",
        },
        {
          id: 7,
          pic: require("@/assets/mianAssets/label6_assets/礼物.png"),
          title: "新手礼包",
          tip: "例：新用户成功进入商场后点击领取",
        },
        {
          id: 8,
          pic: require("@/assets/mianAssets/label6_assets/礼物.png"),
          title: "弹窗",
          tip: "例：小程序弹窗广告、导航、礼包",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    closeVal(data) {
      this.isShow = data;
    },
    activityShow(i) {
      this.isShow = !this.isShow;
      this.label = --i;
      console.log(i);
    },
    closeClick() {
      this.isShow = true;
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
